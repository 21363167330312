import {
  daysCamelCase,
  daysFullNames,
  monthsCamelCase,
  monthFullNames
} from "./dates";

/**
 * This function returns date in specified format.
 * 
 * @param {string} format Format in which date to be returned.
 * @param {date} [date] Date to be initialized.
 *
 * @returns {string} Date in specified format.
 * 
 * @example
 * formatDate("MMMM, YYYY") -> "October, 2019"
 * */
const formatDate = (format, date) => {
  /** FORMATS */
  // D    - Day of Month; 1 2 ... 30 31
  // DD   - Day of Month; 01 02 ... 30 31
  // ddd  - Day of Week; Sun Mon ... Fri Sat
  // dddd - Day of Week; Sunday Monday ... Friday Saturday
  // M    - Month; 1 2 ... 11 12
  // MM   - Month; 01 02 ... 11 12
  // MMM  - Month; Jan Feb ... Nov Dec
  // MMMM - Month; January February ... November December
  // YY   - Year; 70 71 ... 29 30
  // YYYY - Year; 1970 1971 ... 2029 2030
  // H    - Hours; 0 1 ... 22 23
  // HH   - Hour; 00 01 ... 22 23
  // h    - Hour; 1 2 ... 11 12
  // hh   - Hour; 01 02 ... 11 12
  // m    - Minute; 0 1 ... 58 59
  // mm   - Minute; 00 01 ... 58 59
  // s    - Second; 0 1 ... 58 59
  // ss   - Second; 00 01 ... 58 59
  // A    - AM/PM
  // a    - am/pm

  const REGEX_FORMAT = /\[([^\]]+)]|D{1,2}|d{1,4}|M{1,4}|Y{2,4}|H{1,2}|h{1,2}|m{1,2}|s{1,2}|A|a/g

  let result = format || "";
  let dateObj;

  if (date) {
    dateObj = new Date(date);
  } else {
    dateObj = new Date();
  }

  result = result.replace(REGEX_FORMAT, (match, $1) => $1 || formatter(match, dateObj));

  return result;
}

/**
 * This is helper function for formatDate.
 * 
 * @param {string} match Matched format in which date to be returned.
 * @param {date} [dateObj] Date to be used for formatting.
 *
 * @returns {string} Date in specified format.
 * 
 * */
const formatter = (match, dateObj) => {
  let result;

  switch (match) {
    case "D":
      result = dateObj.getDate();
      break;
    case "DD":
      result = dateObj.getDate();
      if (result < 10) {
        result = `0${result}`;
      }
      break;
    case "ddd":
      result = daysCamelCase[dateObj.getDay()];
      break;
    case "dddd":
      result = daysFullNames[dateObj.getDay()];
      break;
    case "M":
      result = dateObj.getMonth() + 1;
      break;
    case "MM":
      result = dateObj.getMonth() + 1;
      if (result < 10) {
        result = `0${result}`;
      }
      break;
    case "MMM":
      result = monthsCamelCase[dateObj.getMonth()];
      break;
    case "MMMM":
      result = monthFullNames[dateObj.getMonth()];
      break;
    case "YY":
      result = dateObj
        .getFullYear()
        .toString()
        .substr(-2);
      break;
    case "YYYY":
      result = dateObj.getFullYear();
      break;
    case "h":
      result = dateObj.getHours();
      if (result > 12) {
        result -= 12;
      }
      break;
    case "hh":
      result = dateObj.getHours();
      if (result > 12) {
        result -= 12;
      }
      if (result < 10) {
        result = `0${result}`;
      }
      break;
    case "H":
      result = dateObj.getHours();
      break;
    case "HH":
      result = dateObj.getHours();
      if (result < 10) {
        result = `0${result}`;
      }
      break;
    case "m":
      result = dateObj.getMinutes();
      break;
    case "mm":
      result = dateObj.getMinutes();
      if (result < 10) {
        result = `0${result}`;
      }
      break;
    case "s":
      result = dateObj.getSeconds();
      break;
    case "ss":
      result = dateObj.getSeconds();
      if (result < 10) {
        result = `0${result}`;
      }
      break;
    case "A":
      result = dateObj.getHours();
      if (result < 12) {
        result = "AM";
      } else {
        result = "PM";
      }
      break;
    case "a":
      result = dateObj.getHours();
      if (result < 12) {
        result = "am";
      } else {
        result = "pm";
      }
      break;
    default:
      result = "";
  }

  return result;
}

export default formatDate;
