import gql from "graphql-tag";
import { clientHelper } from "utils/graphQL";
import { mLog } from "utils/logger";

export const queryFields = `
  id
  display_status_to_candidate
  state
  stage
  screen_status
  latest_working_day
  job_location{
    place_id
  }
  job {
    id
    title
    pricing_plan_type
    latest_question_round
    interview_content
    organization {
      id
      name
      popular_name
      interview_content
    }
    client_approval_required
    is_screening_required
    pricing_plan_type
    functional_area {
      name
      id
      interview_training_content {
        id
        content
      }
    }
    application_metrics {
      joinings
      selections
      previous_interviews
      upcoming_interviews
    }
    offer{
      salary_format
      min_offered_salary
      max_offered_salary
    }
    places{
      place_id
      short_formatted_address
    }
    vertical
  }
  candidate {
    id
    user {
      id
    }
  }
  created_by {
    id
    user_type
  }
  recent_slot
  interview {
    id
    date
    location {
      address
      geo_location
      google_place_id
    }
    going_for_interview
    attendance
    type
    start_time
    end_time
  }
  selection {
    salary_offered
    date_of_joining
    joining_status_by_candidate
    date_of_leaving
    monthly_net_salary
  }
  call_hr{
    name
    contact
  }
  job_location {
    place_id
    locality
  }
  is_job_outside_candidate_city
`;


const OKAY_GO_APPLICATION_FIELDS = `
  partner_detail{
    partner_id
    partner_name
    company_name
    logo
    paymentConfiguration
    shift_end_time
    shift_start_time
    offer_letter_name
    is_active
    inserted_by
    inserted_on
    updatedBy
    updatedOn
    groupId
    is_aj_client
    shipments_allocate_flag
  }
  job_application_details{
    assign_id
    jobId
    userId
    insertedOn
    isConfirmed
    confirmedBy
    remark
    applied_on
    insertedBy
    updatedBy
    updatedOn
    interviewStatus
    interviewStatusUpdatedBy
    selectionStatus
    selectionStatusUpdatedBy
    interview_location
    onboarded
    interviewDate
  }
  job_detail{
    jobId
    jobRole
    jobStatus
    hubAddress
    hub_name
    interview_days
    interview_location
    interviewStartTime
    interviewEndTime
    interviewInstruction
    noOfOpenings
    salaryType
    salary
    maxSalary
    spocNumber
    spocName
    companyName
    companyId
    insertedBy
    insertedOn
    updatedOn
    updatedBy
    jobClosingDate
    city
    shiftStartTime
    shiftEndTime
    shiftHours
    earningPerHour
    earningPerDay
    area
    interviewInstruction
    gender
    hubLatitude
    hubLongitude
    jobType
    singleDayDate
  }
  application_status
  client_id
  }
  __typename
`;

const ACTIVE_APPLICATION_FILTERS = `filter:{
  Application:{
    OR :[
      {
        stage: CANDIDATE_APPLIED
      },
      {
        stage: EMPLOYER_SHORTLISTED
      },
      {
        stage: TOBE_SCHEDULED_FOR_INTERVIEW
      },
      {
        stage: SCHEDULED_FOR_INTERVIEW
      },
      {
        stage: SELECTED
      },
      {
        stage: AGREED_TO_JOIN
      },
      {
        stage: JOINED
      }
    ]
  }
}`;

const INTERVIEW_APPLICATION_FILTERS = `filter:{
  Application:{
    OR :[
      {
        stage: SCHEDULED_FOR_INTERVIEW
      },
      {
        stage: AGREED_TO_JOIN
      },
      {
        stage: DIDNT_AGREE_TO_JOIN
      },
      {
        stage: JOINED
      },
      {
        stage: DIDNT_JOIN
      },
      {
        stage: LEFT_JOB
      }
    ]
  }
}`;

export const getApplicationQuery = (applicationId, activeTab) => {
  let returnQuery;
  if (applicationId) {
    returnQuery = gql`
    query application {
      node(type:Application id: "${applicationId}") {
        ... on Application {
          ${queryFields}
        }
      }
    }
    `;
  } else if (activeTab === "Interviews") {
    returnQuery = gql`
    query application($pageCursor: String) {
      nodes(type: Application, pageCursor: $pageCursor, sort: "modified__DESC", ${INTERVIEW_APPLICATION_FILTERS}) {
        totalCount
        pageInfo {
          totalPages
          pageNumber
          nextCursor
        }
        edges {
          ... on Application {
            ${queryFields}
          }
        }
      }
    }
  `;
  } else {
  //   returnQuery = gql`
  //   query application($pageCursor: String) {
  //     nodes(type: Application, pageCursor: $pageCursor, sort: "modified__DESC") {
  //       totalCount
  //       pageInfo {
  //         totalPages
  //         pageNumber
  //         nextCursor
  //       }
  //       edges {
  //         ... on Application {
  //           ${queryFields}
  //         }
  //       }
  //     }
  //   }
  // `;
  // returnQuery = gql`
  //   query getAllApplications($user_id: ID) {
  //     totalCount
  //     pageInfo {
  //       totalPages
  //       pageNumber
  //       nextCursor
  //     }
  //     edges {
  //       ... on Application {
  //         ${queryFields}
  //       }
  //       ... onOkayGoApplication{
  //         partner_detail{
  //           shift_end_time
  //           shift_start_time
  //         }
  //         job_application_details{
  //           insertedOn
  //           isConfirmed
  //         }
  //         job_detail{
  //           interviewInstruction
  //         }
  //       }
  //     }
  //   }
  // `;
  returnQuery = gql`
  query getAllApplications{
    getAllApplications{
      totalCount
      pageInfo{
        totalPages
        nextCursor
      }
      edges{
        ... on Application{
          ${queryFields}
        }
        __typename
        ...on OkayGoApplication{
          ${OKAY_GO_APPLICATION_FIELDS}
      }
      }
    
    }
  `
  }
  return returnQuery;
};

export const APPLICATION_QUERY = gql`
  query application($pageCursor: String) {
    nodes(type: Application, pageCursor: $pageCursor, sort: "modified__DESC") {
      totalCount
      pageInfo {
        totalPages
        pageNumber
        nextCursor
      }
      edges {
        ... on Application {
          ${queryFields}
        }
      }
    }
  }
`;

export const INTERVIEW_APPLICATION_COUNT = gql`
  query interviewApplicationCount {
    nodes(type: Application, ${INTERVIEW_APPLICATION_FILTERS}) {
      totalCount
    }
  }
`;

export const INTERVIEW_DETAIL = gql`
query application($id: ID!) {
  node(type:Application id: $id) {
    ... on Application {
      ${queryFields}
    }
  }
}
`;

export const APPLY_JOB = gql`
  mutation Apply($type: NodeMutationTypes!, $id: ID) {
    mutate(type: $type, id: $id) {
      ... on ApplicationMutation {
        accept {
          ${queryFields}
        }
      }
    }
  }
`;

export const GetQuery = gql`
  {
    reasons {
      declineOffer(current_stage: SELECTED) {
        id
        name
      }
    }
  }
`;

export const GET_CMS_SCORE = gql`
  query application($id: ID!) {
  node(type:Application id: $id) {
    ... on Application {
      id
      cms{
        score
      }
    }
  }
}
`

export const getCMSScore = async (applicationId, client) => {
  const {data, error} = await clientHelper('query')(client)({
    query: GET_CMS_SCORE,
    variables: {
      id: applicationId
    }
  })
  if (error) {
    mLog("ERROR in getting application", error);
  } 
  return data;
}