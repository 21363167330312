import fetch from "isomorphic-unfetch";
import config from "config";
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
// import { mLog, mWarn } from "utils/logger";
import { getAuthLink, getErrorLink } from "utils/authentication/initUtils";
// this logger is for debugging purpose on track js
import apolloLogger from "apollo-link-logger";
import { cache } from "../cache";

let apolloClient = null;

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

// const defaultState = {
//   jobSearchFlow: {
//     __typename: "jobSearchFlow",
//     // suggestionState: "progressive",
//     suggestionState: "blank",
//     locationFocused: false,
//     // locationText: "",
//     // chips: [],
//     isTextPresent: false,
//     searchKeywordText: "",
//     FAFocused: true,
//     latitude: null,
//     longitude: null,
//     city: null
//   }
// };

// raw request response logging for debugging on track js
// const customFetchWithLogging = (...args) => {
//   return new Promise((resolve, reject) => {
//     fetch(...args)
//       .then(res => {
//         resolve(res.clone());
//         res.json().then(data => {
//           mLog("req=", args);
//           mLog(
//             "res=\n headers: ",
//             res.headers,
//             "\n status: ",
//             res.status,
//             "\n statusText: ",
//             res.statusText.toString(),
//             "\n type:",
//             res.type,
//             "\n url:",
//             res.url
//           );
//           mLog("real data=", data);
//         });
//       })
//       .catch(error => {
//         mWarn("raw fetch error", error);
//         reject(error);
//       });
//   });
// };

function create(initialState, options) {
  const httpOptions = {
    uri: config.API_ENDPOINT,
    credentials: "same-origin"
    // fetch: customFetchWithLogging
  };

  // https://medium.com/@karensgrigorjancs/effortless-file-uploads-with-activestorage-apollo-and-react-7a50929c40ca

  // const httpLink = ApolloLink.split(
  //   operation => operation.getContext().hasUpload,
  //   createUploadLink(httpOptions),
  //   new BatchHttpLink(httpOptions)
  // );

  // const cache = new InMemoryCache();

  const httpLink = createUploadLink(httpOptions);

  const authLink = getAuthLink(options.getToken);
  const errorLink = getErrorLink(options);

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([
      apolloLogger,
      errorLink.concat(authLink.concat(httpLink))
    ]),
    cache
  });
}

export default function initApollo(initialState, options) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState, options);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options);
  }

  return apolloClient;
}
