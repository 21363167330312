import config from "config";
import {
  replaceSubscribtionWithFCMToken,
  updateFCMToken
} from "screens/common/NotificationConsent/utils";

const loadSetupScript = ({ newRegistration, apolloClient }) => {
  const setupScript = document.createElement("script");
  setupScript.type = "text/javascript";
  setupScript.async = true;
  setupScript.src =
    "https://www.gstatic.com/firebasejs/7.14.4/firebase-messaging.js";

  setupScript.onload = () => {
    // App's Firebase configuration
    const firebaseConfig = {
      apiKey: config.FIREBASE_KEY,
      authDomain: config.FIREBASE_AUTH_DOMAIN,
      databaseURL: config.FIREBASE_DATABASE_URL,
      projectId: config.FIREBASE_PROJECT_ID,
      storageBucket: config.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
      appId: config.FIREBASE_APP_ID
    };
    // Initialize Firebase
    window.firebase.initializeApp(firebaseConfig);

    // Retrieve Firebase Messaging object.
    window.messaging = window.firebase.messaging();

    // Add the public key generated from the console here.
    window.messaging.usePublicVapidKey(config.FIREBASE_VAPID_KEY);

    const { messaging } = window;
    console.log("newRegistration firebase ###########", newRegistration);
    messaging.useServiceWorker(newRegistration);

    replaceSubscribtionWithFCMToken(apolloClient);
    messaging.onTokenRefresh(() => {
      messaging
        .getToken()
        .then(refreshedToken => {
          updateFCMToken(apolloClient, refreshedToken);
        })
        .catch(err => {});
    });
    messaging.onMessage(payload => {
      console.log("Push Notification ################### ", payload);
    });
  };
  document.body.appendChild(setupScript);
};

const initializeFirebaseMessaging = ({ newRegistration, apolloClient }) => {
  // firebase
  const firebase = document.createElement("script");
  firebase.type = "text/javascript";
  firebase.async = true;

  firebase.src = "https://www.gstatic.com/firebasejs/7.14.4/firebase-app.js";

  document.body.appendChild(firebase);
  /* eslint-disable */
  firebase.onload = () => {
    loadSetupScript({ newRegistration, apolloClient });
  };
};

export default initializeFirebaseMessaging;
