import { atob, btoa } from "isomorphic-base64";
import { setCookie, getCookie, removeCookie } from "../../lib/session";
import { mWarn } from "../logger";

const cookieName = "loginaj";
let myToken = null;

const serializeToken = (tokenObj) => btoa(JSON.stringify(tokenObj));

export const persistToken = (tokenObj) => {
  if (process.browser) {
    setCookie(cookieName, serializeToken(tokenObj));

    if (!tokenObj.hasOwnProperty("token")) {
      if (window.trackJs)
        trackJs.track(
          "Received an invalid Token while storing to cookies. 'token' param missing."
        );
    }
    if (!tokenObj.hasOwnProperty("refresh_token")) {
      if (window.trackJs)
        trackJs.track(
          "Received an invalid Token while storing to cookies. 'refresh_token' param missing."
        );
    }
  }
};

export const persistTokenUniversal = (res) => (tokenObj) => {
  if (process.browser) {
    persistToken(tokenObj);
  } else {
    setCookie(cookieName, serializeToken(tokenObj), res);
  }
};

export const signOut = () => {
  if (process.browser) {
    removeCookie(cookieName);
  }
};

export const signOutUniversal = (res) => {
  if (process.browser) {
    signOut();
  } else {
    removeCookie(cookieName, res);
  }
};

export const getToken = (req) => {
  // if (myToken) return myToken;
  const data = getCookie(cookieName, req);

  if (data) {
    try {
      return JSON.parse(atob(data));
    } catch (error) {
      mWarn("Error while parsing", error);
      return null;
    }
  }

  return null;
};
