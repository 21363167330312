/* eslint-disable react/no-danger */
import React, { Fragment } from "react";
import { Platform } from "react-native";
import App from "next/app";
import Head from "next/head";
import { ApolloProvider } from "@apollo/client";
import { mLog, mWarn } from "utils/logger";
import NavHistory from "utils/routing/NavHistory";
import { getRouter } from "utils/routing/NavRouter";
import config from "config";
import "../lib/polyfills";
import formatDate from "utils/formatDate";
import { pushClevertapEvent } from "utils/clevertapEvents";
import AjToWjTransitionCard from "screens/common/AjToWjTransitionCard";
import {
  retrieveDataFromAsyncStorage,
  storeDataToAsyncStorage
} from "screens/common/utils";
import { TrackJS } from "trackjs";
import { activateGoogleOptimize } from "utils/googleOptimize";
import { DefaultTemplate } from "utils/googleOptimize/templates";
import { APPLICATION_QUERY } from "screens/Application/utils/query";
import VernacManager from "utils/vernacular/";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";
// import AsyncStorage from "@react-native-async-storage/async-storage";
import { AsyncStorage } from "react-native-web";
import initializeFirebaseMessaging from "../lib/Firebase";
import withApolloClient from "../lib/withApolloClient";
import { getCookie, setCookie, removeCookie } from "../lib/session";
import {
  register,
  addChangeListener,
  onWaiting,
  checkForWorkerUpdates
} from "../lib/service-worker";
import Wootric from "../lib/Wootric";
import { cache } from "../cache";

const SCHEMA_VERSION = "3"; // Must changed on schema change
const SCHEMA_VERSION_KEY = "apollo-schema-version";

const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

const DefaultHead = ({ pathname, isSarkariJob, isCanonical }) => {
  const finalHref = config.DESKTOP_WEB_BASE_URL + pathname;
  const formattedDate = formatDate("MMMM, YYYY");
  const title = `Job Search India - ${formattedDate} entry level vacancies - Rocket`;
  let shouldLoadInspectlet;
  if (process.browser) {
    shouldLoadInspectlet = getRandomInt(100) < 10; // load only for 10% of the users
    if (shouldLoadInspectlet) {
      pushClevertapEvent("Inspectlet loaded", {});
    }
  }
  return (
    <Head>
      <title key="title">{title}</title>
      <link rel="icon" type="image/ico" href="/static/favicon.ico" />
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=0"
      />
      <meta name="viewport" content="initial-scale=1.0, maximum-scale=2.0" />
      <meta key="author" name="author" content="Rocket" />
      <meta name="theme-color" content="#3477ff" />
      <meta
        key="description"
        name="description"
        content="Search latest job vacancies in Delivery, Sales, Part-time, Govt, Sarkari Jobs in top companies on MyRocket.co. Get free job alerts, create resume and apply free"
      />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="application-name" content="Rocket" />
      <meta name="apple-mobile-web-app-title" content="Rocket" />
      <meta name="msapplication-navbutton-color" content="#3477ff" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta name="msapplication-starturl" content="/" />
      <link rel="manifest" href="/static/manifest.json" />
      <meta property="place:location:latitude" content="19.11066" />
      <meta property="place:location:longitude" content="72.88706" />
      <meta property="business:contact_data:locality" content="Mumbai" />
      <meta property="business:contact_data:postal_code" content="400072" />
      <meta property="business:contact_data:country_name" content="India" />
      <meta
        property="business:contact_data:email"
        content="info@waahjobs.com"
      />
      <meta
        property="business:contact_data:phone_number"
        content="+912244446666"
      />
      <meta
        property="business:contact_data:website"
        content="https://www.waahjobs.com"
      />
      <meta name="msvalidate.01" content="894C4A3904742319AE24E1CB021B1EB5" />
      <meta property="profile:username" content="waahjobs" />
      <meta property="og:fbid" content="232556326898725" />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="Rocket" />
      <meta property="og:see_also" content="https://www.myrocket.co" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content=" https://twitter.com/RocketAppBP" />
      <meta
        name="facebook-domain-verification"
        content="h4xv1a1qpotymlmp3bs2o7d9uelz5g"
      />
      {/* <link rel="canonical" href="https://www.waahjobs.com/" + pathname /> */}
      {/* <link rel="canonical" href={finalHref} /> */}
      {!(isCanonical.length > 0) && <link rel="canonical" href={finalHref} />}
      <link
        rel="apple-touch-startup-image"
        media="(width: 375px) and (height: 812px) and (min-resolution: 3dppx)"
        href="/static/apple-launch-1125x2436.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="(width: 375px) and (height: 667px) and (min-resolution: 2dppx)"
        href="/static/apple-launch-750x1334.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="(width: 414px) and (height: 736px) and (min-resolution: 3dppx)"
        href="/static/apple-launch-1242x2208.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="(width: 320px) and (height: 568px) and (min-resolution: 2dppx)"
        href="/static/apple-launch-640x1136.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="(width: 768px) and (height: 1024px) and (min-resolution: 2dppx)"
        href="/static/apple-launch-1536x2048.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="(width: 834px) and (height: 1112px) and (min-resolution: 2dppx)"
        href="/static/apple-launch-1668x2224.png"
      />
      <link
        rel="apple-touch-startup-image"
        media="(width: 1024px) and (height: 1366px) and (min-resolution: 2dppx)"
        href="/static/apple-launch-2048x2732.png"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        sizes="192x192"
        href="/static/logo_192x192.png"
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        sizes="512x512"
        href="/static/logo_512x512.png"
      />
      {isSarkariJob && (
        <link
          href="https://fonts.googleapis.com/css?family=Noto+Sans|Source+Sans+Pro&display=swap"
          rel="stylesheet"
        />
      )}
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucen"
      />
      {/* Global site tag START (gtag.js) - Google Analytics */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_PROPERTY_ID}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","${config.GA_PROPERTY_ID}");`
        }}
      />
      {/* Global site tag END (gtag.js) - Google Analytics */}
      {/* Google Tag Manager START */}
      <script
        dangerouslySetInnerHTML={{
          __html: `!function(e,t,a,n){e[n]=e[n]||[],e[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"}),n=t.getElementsByTagName(a)[0],(a=t.createElement(a)).async=!0,a.src="https://www.googletagmanager.com/gtm.js?id=${config.GTM_MOBILE_WEB_ID}",n.parentNode.insertBefore(a,n)}(window,document,"script","dataLayer");`
        }}
      />
      {/* Google Tag Manager END */}
      {/* Google Optimize START  */}
      <script
        async
        src={`https://www.googleoptimize.com/optimize.js?id=${config.GA_OPTIMIZE_CONTAINER_ID}`}
      />
      {/* Google Optimize END  */}
      {/* Inspectlet START */}
      {shouldLoadInspectlet && (
        <script
          dangerouslySetInnerHTML={{
            __html: `window.__insp=window.__insp||[],__insp.push(["wid",353407773]),!void setTimeout(function(){var t,e;void 0===window.__inspld&&(window.__inspld=1,(t=document.createElement("script")).async=!0,t.id="inspsync",t.src=("https:"==document.location.protocol?"https":"http")+"://cdn.inspectlet.com/inspectlet.js?wid=353407773&r="+Math.floor((new Date).getTime()/36e5),(e=document.getElementsByTagName("script")[0]).parentNode.insertBefore(t,e))},0);`
          }}
        />
      )}
      {/* Inspectlet END */}
    </Head>
  );
};

class MyApp extends App {
  state = {
    refresh: 1
  };

  async componentDidMount() {
    if (!VernacManager.getSelectedLanguage()) {
      await VernacManager.initialize();
      this.setState({ refresh: 2 });
    }
    // i trieid setting maxSize to less than 2 mb but iit diid not wrk.
    const persistor = new CachePersistor({
      cache,
      storage: new LocalStorageWrapper(AsyncStorage)
    });

    const currentVersion = await AsyncStorage.getItem(SCHEMA_VERSION_KEY);

    if (currentVersion === SCHEMA_VERSION) {
      // If the current version matches the latest version,
      // we're good to go and can restore the cache.
      await persistor.restore();
    } else {
      // Otherwise, we'll want to purge the outdated persisted cache
      // and mark ourselves as having updated to the latest version.
      await persistor.purge();
      await AsyncStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }

    this.installTrackJS();

    const jobCardVariant = await activateGoogleOptimize(
      config.GA_JOB_CARD_EXP_ID,
      DefaultTemplate
    );

    if (jobCardVariant) {
      storeDataToAsyncStorage("jobCardVariant", jobCardVariant);
    } else {
      storeDataToAsyncStorage("jobCardVariant", "A");
    }

    const callHrVariant = await activateGoogleOptimize(
      config.GA_CALL_HR_EXP_ID,
      DefaultTemplate
    );

    if (callHrVariant) {
      storeDataToAsyncStorage("callHrVariant", callHrVariant);
    } else {
      storeDataToAsyncStorage("callHrVariant", "B");
    }

    const variantValue = await activateGoogleOptimize(
      config.GA_PREQUALIFIED_CANDIDATE_EXP_ID,
      DefaultTemplate
    );

    if (variantValue) {
      storeDataToAsyncStorage("candidateVariant", "A");
    } else {
      storeDataToAsyncStorage("candidateVariant", "A");
    }

    // ONBOARDING VARIANT
    // const onboardingVariant = await activateGoogleOptimize(
    //   config.GA_ON_BOARDING_PHASE_4_EXP_ID,
    //   DefaultTemplate
    // );
    // if (onboardingVariant) {
    //   storeDataToAsyncStorage("onboardingVariant", onboardingVariant);
    // } else {
    //   storeDataToAsyncStorage("onboardingVariant", "A");
    // }

    //---------CONCLUDED ONBOARDING VARIANT TO A (with personal details) --------
    storeDataToAsyncStorage("onboardingVariant", "A");

    // const recommendedJobsVariant = await activateGoogleOptimize(
    //   config.GA_RECOMMEND_JOBS_V2_EXP_ID,
    //   DefaultTemplate
    // );
    // if (recommendedJobsVariant === "B") {
    //   storeDataToAsyncStorage("recommendedJobsVariant", "NEW_RECO");
    // } else {
    //   storeDataToAsyncStorage("recommendedJobsVariant", "OLD_RECO");
    // }
    storeDataToAsyncStorage("recommendedJobsVariant", "NEW_RECO");

    const { apolloClient: client } = this.props;
    const isLoggedIn = getCookie("loginaj");
    if (isLoggedIn) {
      this.toggleBannerVisibility();
      const applicationData = await retrieveDataFromAsyncStorage(
        "userApplicationsData"
      );
      const { data } = await client.query({
        query: APPLICATION_QUERY
      });

      const { edges } = data.nodes;
      const res = edges.reduce((s, a) => {
        s[a.job.id] = {
          stage: a.stage,
          status: a.display_status_to_candidate,
          recentSlot: a.recent_slot
        };
        return s;
      }, {});

      if (!applicationData) {
        storeDataToAsyncStorage("userApplicationsData", res);
        storeDataToAsyncStorage("notifyUserNavbar", true);
        storeDataToAsyncStorage("notifyUserProfile", true);
      } else if (applicationData) {
        let newApplicationCount = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const item of Object.keys(res)) {
          if (
            JSON.stringify(applicationData[item]) !== JSON.stringify(res[item])
          ) {
            if (!applicationData[item]) {
              // checking for any new application created
              newApplicationCount += 1;
            } else {
              // change in application status
              storeDataToAsyncStorage("notifyUserNavbar", true);
              storeDataToAsyncStorage("notifyUserProfile", true);
            }
          }
        }
        if (newApplicationCount > 0) {
          storeDataToAsyncStorage("userApplicationsData", res);
        }
      }
    }

    if (window && window.dataLayer) {
      await window.dataLayer.push({ event: "optimize.activate" });
    }

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
    });

    NavHistory.reset();

    getRouter().beforePopState(() => {
      // On pressing browser back button
      // 1. Removing the requested page from history
      // 2. Removing previous page from history
      NavHistory.pop();
      NavHistory.pop();
      return true;
    });

    this.forceLogOutOldLogins();

    mLog("====== initial history", NavHistory.get());

    const serviceWorkerPath =
      this.props.router.asPath.indexOf("/candidate-zone", 0) === 0
        ? "/protected-service-worker.js"
        : "/service-worker.js";

    window.addEventListener("load", async () => {
      try {
        const newRegistration = await register(serviceWorkerPath);
        const { apolloClient } = this.props;
        initializeFirebaseMessaging({ newRegistration, apolloClient });
        onWaiting(newRegistration, (registration) => {
          if (
            registration.waiting.scriptURL !== registration.active.scriptURL
          ) {
            // If it's a different service worker, directly skip waiting
            registration.waiting.postMessage("skipWaiting");
          } else {
            // Here it's an update
            addChangeListener();
            // We wait for the user to allow refreshing!
            // promptUserToRefresh(registration);
          }
        });
        checkForWorkerUpdates();
      } catch (error) {
        mWarn("Service worker failed", error);
      }
    });
  }

  toggleBannerVisibility = async () => {
    const bannerVisibilityExpiry = await retrieveDataFromAsyncStorage(
      "bannerVisibilityExpiry"
    );
    const bannerVisibilityCount = await retrieveDataFromAsyncStorage(
      "bannerVisibilityCount"
    );

    if (bannerVisibilityExpiry && bannerVisibilityCount) {
      const currentDate = new Date();
      const bannerExpiryDate = new Date(bannerVisibilityExpiry);
      if (currentDate <= bannerExpiryDate) {
        if (bannerVisibilityCount === 2) {
          storeDataToAsyncStorage("showCandidateBanner", false);
        }
      } else {
        storeDataToAsyncStorage("bannerVisibilityCount", 2);
        storeDataToAsyncStorage("showCandidateBanner", true);
        const bannerExpiry = new Date(bannerVisibilityExpiry);
        bannerExpiry.setDate(bannerExpiry.getDate() + 7);
        storeDataToAsyncStorage(
          "bannerVisibilityExpiry",
          bannerExpiry.toString()
        );
      }
    } else {
      storeDataToAsyncStorage("showCandidateBanner", true);
      storeDataToAsyncStorage("bannerVisibilityCount", 2);
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 7);
      storeDataToAsyncStorage("bannerVisibilityExpiry", currentDate.toString());
    }
  };

  installTrackJS = () => {
    if (!TrackJS.isInstalled()) {
      TrackJS.install({
        token: `${config.TRACKJS_TOKEN}`,
        application: `${config.TRACKJS_APP}`,
        window: { enabled: false },
        enabled: window.location.host.indexOf("localhost") !== 0
      });
    }
    // console.error("Testing TrackJS", "console.error function");
    // console.log("Testing TrackJS", "console.log function");
  };

  forceLogOutOldLogins = () => {
    const mobileWebCookies = ["userId", "candidate_id", "auth_token"];
    if (
      mobileWebCookies.map((name) => getCookie(name)).filter((value) => !!value)
        .length > 0 &&
      !getCookie("loginaj")
    ) {
      // If users are logged in using the old system..., forcibly log them out.
      // Don't redirect, it will be handled by the incoming pages
      const domain = window.location.hostname;
      mobileWebCookies.forEach((name) =>
        removeCookie(name, undefined, { domain })
      );
      // Try once deleting using the domain
      mobileWebCookies.forEach((name) => removeCookie(name, undefined));
    }
  };

  setUTMCookies = (utmCookies, filterObject) => {
    if (utmCookies.filter((name) => name in filterObject).length > 0) {
      utmCookies.forEach((name) => {
        let filterItem = filterObject[name];
        if (filterObject[name] instanceof Array) {
          filterItem = filterObject[name][0];
        }
        setCookie(name, filterItem || "", undefined, { expires: 30 });
      });
    }
  };

  render() {
    const { Component, pageProps, apolloClient, router } = this.props;

    const [pathname, queryParams] = router.asPath.split("?");
    const isRedirectedFromAJ =
      queryParams && queryParams.includes("redirectFromAJ=true");
    const { asPath } = router;
    const isSarkariJob =
      (asPath && asPath.split("/").indexOf("sarkari-jobs-results") !== -1) ||
      false;

    const numbers = [
      "/jobs-by-city/",
      "/jf/delivery-boy-jobs/",
      "/jf/dunzo-jobs/",
      "/q/10th-pass-jobs/",
      "/q/graduate-jobs/",
      "/q/12th-pass-jobs/",
      "/q/jobs-in-bangalore/",
      "/q/part-time-jobs/",
      "/q/work-from-home-jobs/",
      "/q/delivery-boy-jobs/",
      "/q/freshers-jobs-in-bangalore/",
      "/q/freshers-jobs/"
    ];

    const isCanonical = numbers.filter((item) => {
      return asPath.indexOf(item) !== -1;
    });

    return (
      <Fragment>
        <DefaultHead
          pathname={pathname}
          isSarkariJob={isSarkariJob}
          isCanonical={isCanonical}
        />

        <ApolloProvider client={apolloClient}>
          {isRedirectedFromAJ && <AjToWjTransitionCard />}
          {this.state.refresh && <Component {...pageProps} />}
        </ApolloProvider>

        {config.ENV_TYPE === "production" && process.browser && (
          <Wootric {...pageProps} client={apolloClient} />
        )}
      </Fragment>
    );
  }
}

export default withApolloClient(MyApp);
