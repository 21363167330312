/* https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68
https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorker/onstatechange */
/* Define utilities to deal with service worker */

import { mLog, mWarn } from "utils/logger";
import { Router } from "../routes";

const UPDATE_THRESHOLD = 3600 * 6 * 1000; // check for updates every 6 hours

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

export async function register(swPath = "/service-worker.js") {
  if ("serviceWorker" in navigator) {
    const registration = await navigator.serviceWorker.register(swPath);
    mLog("SW registered: ", registration);
    return registration;
  }
  return new Promise((_, reject) =>
    reject(new Error("No Service Worker Support!"))
  );
}

/* Call the callback function once the new service worker is in the state waiting! */

// export function onWaiting(registration, callback) {
//   registration.onupdatefound = () => {
//     // make sure to only enter onwaiting when there has been an update
//     if (!registration.active) return; // there is no conflict of service workers.. so will never wait
//     let calledCallback = false;
//     const intervalID = setInterval(() => {
//       if (calledCallback) {
//         clearInterval(intervalID);
//         return;
//       }

//       mLog(
//         "trying to check service worker!",
//         registration.active,
//         registration.installing,
//         registration.waiting,
//         registration.active === null,
//         registration.installing === null,
//         registration.waiting === null
//       );

//       if (registration.waiting) {
//         mLog("got in finally!");
//         callback(registration);
//         calledCallback = true;
//       }

//       if (!registration.installing && !registration.waiting) {
//         // the order of the conditions might matter here because of references
//         mLog("weird edge case");
//         calledCallback = true; // quit iterating
//       }
//     }, 100);
//   };
// }

export function onWaiting(registration, callback) {
  registration.onupdatefound = () => {
    // make sure to only enter onwaiting when there has been an update
    // Have to be very careful about references here. At any point registration.installing might become unset....
    // need some mutex here to be sure

    if (!registration.active) return; // there is no conflict of service workers.. so will never wait

    const serviceWorker = registration.installing || registration.waiting;
    mLog("trying to check service worker!", registration);

    try {
      serviceWorker.addEventListener("statechange", e => {
        mLog("service worker event change", e.target);
        if (e.target && e.target.state === "installed") {
          // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorker/state
          mLog("transitioned to waiting");
          callback(registration);
        }
      });

      return;
    } catch (error) {
      mWarn("caught error and trying again", error); // call in try might fail if serviceWorker = registration.installing which becomes null
      // fall through
    }

    if (registration.waiting) {
      mLog("back up call");
      callback(registration);
      return;
    }

    mWarn("Invalid condition!! Bug in onWaiting");
  };
}

export function addChangeListener() {
  let refreshing;
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    });
  }
}

export function promptUserToRefresh(reg) {
  if (window.confirm("New version available! OK to refresh?")) {
    reg.waiting.postMessage("skipWaiting");
  }
}

export function checkForWorkerUpdates() {
  navigator.serviceWorker.ready.then(registration => {
    let prevTime = new Date().getTime();

    Router.events.on("routeChangeComplete", () => {
      const newTime = new Date().getTime();
      if (newTime - prevTime > UPDATE_THRESHOLD) {
        mLog("checking for service worker update", registration);
        prevTime = newTime;
        registration.update();
      }
    });
  });
}

export function addPushListener() {
  navigator.serviceWorker.addEventListener("push", event => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        const promiseChain = registration.showNotification("Hello, World.");
        event.waitUntil(promiseChain);
      });
    }
  });
}