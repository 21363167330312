import React from "react";
import { setValue, getValue } from "utils/LocalKeyValueStorage";
import { View, StyleSheet, Platform } from "react-native";
import { graphQLClientMutationHelper } from "utils/graphQL";
import {
  pushClevertapEvent,
  pushClevertapUserProperties
} from "utils/clevertapEvents";
import SText from "styled42/components/SText";
import {
  ADD_ENDPOINT,
  SOFT_DELETE_NON_FCM_ENDPOINT,
  UPDATE_ANDROID_PUSH_TOKEN
} from "screens/common/NotificationConsent/query";
import { Right } from "styled42/icons";
import Colors from "styled42/config/colors";

const CURRENT_FCM_TOKEN_AT_SERVER_FOR_MOBILE_WEB = "existing_fcm_token";
const SUBSCRIPTION_TO_FCM_TOKEN_REPLACE_REQUIRED =
  "subscribtion_to_fcm_token_replace_required";

export const checkCookie = () => {
  let laterCount = getValue("laterCount");
  if (laterCount) {
    laterCount = parseInt(laterCount, 10);
    if (laterCount >= 3) {
      return false;
    }
  }
  let renderCount = getValue("renderCount");

  if (renderCount) {
    renderCount = parseInt(renderCount, 10);
    if (renderCount >= 3) {
      return false;
    }
    setTimeout(() => {
      setValue("renderCount", renderCount + 1, {
        expires: 1
      });
    });

    if (renderCount === 2) {
      if (laterCount) {
        setValue("laterCount", laterCount + 1, {
          expires: 60
        });
      } else {
        setValue("laterCount", 1, { expires: 60 });
      }
    }
  } else {
    setValue("renderCount", 1, { expires: 1 });
  }
  return true;
};

export const askPermission = () =>
  new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then((permissionResult) => {
    let permissionValue = null;
    let notifyBrowser = "NULL";
    if (permissionResult === "granted") {
      permissionValue = "Allow";
      notifyBrowser = "Y";
    } else if (permissionResult === "denied") {
      permissionValue = "Block";
      notifyBrowser = "N";
    }
    pushClevertapEvent("Stock Browser Consent Response", {
      type: permissionValue
    });
    pushClevertapUserProperties({ "Notify Browser": notifyBrowser });
    return permissionResult;
  });

export const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = window.atob(base64);
  return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
};

export const updateFCMToken = async (apolloClient, refreshedToken) => {
  const currentFcmToken = getValue(CURRENT_FCM_TOKEN_AT_SERVER_FOR_MOBILE_WEB);
  if (currentFcmToken && refreshedToken && currentFcmToken !== refreshedToken) {
    const { data, error } = await graphQLClientMutationHelper(apolloClient)({
      mutation: UPDATE_ANDROID_PUSH_TOKEN,
      variables: { refresh_token: refreshedToken, token: currentFcmToken }
    });
    if (error) {
    } else {
      setValue(CURRENT_FCM_TOKEN_AT_SERVER_FOR_MOBILE_WEB, refreshedToken);
    }
  }
};

export const replaceSubscribtionWithFCMToken = async (apolloClient) => {
  const isModificationRequired =
    getValue(SUBSCRIPTION_TO_FCM_TOKEN_REPLACE_REQUIRED) === undefined;
  const { permission } = Notification;
  const registration = await navigator.serviceWorker.ready;
  const pushSubscriptionObject =
    await registration.pushManager.getSubscription();
  const pushSubscription = JSON.parse(JSON.stringify(pushSubscriptionObject));

  // This case is useful when a user play with his permissions(frequently allow or deny)
  // In such case subscription becomes null and old token becomes invalid
  // messaging.onTokenRefresh anyway official not implemented form fiirebase side.
  if (
    !isModificationRequired &&
    permission === "granted" &&
    !pushSubscription
  ) {
    const newToken = await window.messaging.getToken();
    updateFCMToken(apolloClient, newToken);
    return;
  }

  // this case is used to cover up the mistake when we added aj-production prooject from firebase instead of
  // mobile apps project. for 1 week user subscribed to wrong project , these need to update their fcmToken
  if (!isModificationRequired && permission === "granted" && pushSubscription) {
    const newToken = await window.messaging.getToken();
    updateFCMToken(apolloClient, newToken);
    return;
  }

  if (
    !isModificationRequired ||
    permission !== "granted" ||
    !pushSubscription
  ) {
    return;
  }

  let fcmToken;
  if (window.messaging) {
    fcmToken = await window.messaging.getToken();
  }
  if (!fcmToken) {
    return;
  }
  delete pushSubscription.expirationTime;
  const payload = {
    subscription_object: pushSubscription,
    fcm_token: fcmToken
  };
  const { data, error } = await graphQLClientMutationHelper(apolloClient)({
    mutation: SOFT_DELETE_NON_FCM_ENDPOINT,
    variables: { payload }
  });
  if (error) {
    return;
  }
  setValue(CURRENT_FCM_TOKEN_AT_SERVER_FOR_MOBILE_WEB, fcmToken);
  setValue(SUBSCRIPTION_TO_FCM_TOKEN_REPLACE_REQUIRED, false);
};

export const subscribeUserToPush = async ({
  client,
  functionalArea,
  user,
  latitude,
  longitude,
  type
}) => {
  let fcmToken;
  if (window.messaging) {
    fcmToken = await window.messaging.getToken();
    if (!fcmToken) {
      return false;
    }
    let payload = {
      fcm_token: fcmToken
    };

    if (user) {
      payload = { ...payload, user_id: user.id };
    }
    if (functionalArea) {
      const { id } = functionalArea;
      payload = { ...payload, functional_area_id: id };
    }
    if (latitude && longitude) {
      payload = { ...payload, location: `${latitude}, ${longitude}` };
    }
    const { data, error } = await graphQLClientMutationHelper(client)({
      mutation: ADD_ENDPOINT,
      variables: { payload }
    });

    setValue(SUBSCRIPTION_TO_FCM_TOKEN_REPLACE_REQUIRED, false);

    if (error) {
      if (type === "AB Card") {
        pushClevertapEvent("Job Alert Email A/B Backend response", {
          Status: "Failure",
          Error: error
        });
      } else {
        pushClevertapEvent("Custom Browser Consent Backend Response", {
          Status: "Failure",
          Error: error
        });
      }
      return false;
    }
    setValue(CURRENT_FCM_TOKEN_AT_SERVER_FOR_MOBILE_WEB, fcmToken);
    if (type === "AB Card") {
      pushClevertapEvent("Job Alert Email A/B Backend response", {
        Status: "Success"
      });
    } else {
      pushClevertapEvent("Custom Browser Consent Backend Response", {
        Status: "Success"
      });
    }
    return true;
  }
};

const checkEndpoint = async () => {
  if (
    process.browser &&
    window &&
    "serviceWorker" in window.navigator &&
    "PushManager" in window
  ) {
    const registration = await navigator.serviceWorker.ready;
    let subscription = await registration.pushManager.getSubscription();
    subscription = JSON.parse(JSON.stringify(subscription));
    return subscription;
  }
};

export const getSubscriptionEndpoint = () => {
  if (Platform.OS !== "web") {
    return null;
  }
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new Error("Timed out in 2s"));
    }, 2000);
  });

  return Promise.race([checkEndpoint(), timeout]);
};

export const AllowSuccess = ({ buttonStyle }) => (
  <View style={[styles.container, buttonStyle]}>
    <Right width="16" height="16" fill={Colors.Green[7]} />
    <SText
      size={12}
      weight="bold"
      color={Colors.Green[7]}
      containerStyle={styles.text}
    >
      Allowed
    </SText>
  </View>
);

const styles = StyleSheet.create({
  container: {
    height: 30,
    width: 94,
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 16,
    borderRadius: 2,
    backgroundColor: Colors.Green[2]
  },
  text: {
    marginLeft: 4
  }
});
