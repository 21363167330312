import PropTypes from "prop-types";
import React from "react";
import { View } from "react-native";

const SClickableArea = ({
  touchableType,
  activeOpacity,
  underlayColor,
  onPress,
  onClick,
  disabled,
  containerStyle,
  children,
  ...restProps
}) => {
  return (
    <View
      onClick={disabled ? undefined : (onPress || onClick)}
      style={containerStyle}
      {...restProps}
    >
      {children}
    </View>
  );
};

SClickableArea.defaultProps = {
  accessibilityRole: "button",
  disabled: false
};

SClickableArea.propTypes = {
  accessibilityRole: PropTypes.string,
  disabled: PropTypes.bool
};

export default SClickableArea;
