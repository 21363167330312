import React from "react";
import { StyleSheet, View } from "react-native";
import S3Image from "screens/common/S3Image";
import SText from "styled42/components/SText";
import SButton from "styled42/components/SButton";
import { Colors } from "styled42/config";
import {
  storeDataToAsyncStorage,
  retrieveDataFromAsyncStorage
} from "screens/common/utils";

const Card = (props) => {
  const { setShowCard } = props;
  return (
    <View style={styles.wrapper}>
      <View style={styles.cardContainer}>
        <View style={styles.topSection}>
          <S3Image
            path="OlxIntegration"
            name="aj-to-wj-banner"
            style={{ height: 137, width: "100%" }}
          />
        </View>
        <View style={styles.bottomSection}>
          <SText size={20} color={Colors.BlueGrey[12]} weight={600}>
            Aasaanjobs is now Waahjobs
          </SText>
          <SText
            size={14}
            color="tertiary"
            containerStyle={styles.secondaryInfo}
          >
            Please use your Aasaanjobs username and password to login to
            Waahjobs.
          </SText>
          <SButton
            text="Continue"
            containerStyle={styles.button}
            textProps={{
              size: 16,
              weight: "600"
            }}
            onClick={() => {
              storeDataToAsyncStorage("aj-to-wj-banner-shown", true);
              setShowCard(false);
            }}
          />
        </View>
      </View>
    </View>
  );
};

class AjToWjTransitionCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCard: false
    };
  }

  async componentDidMount() {
    const isCardShownToUser = await retrieveDataFromAsyncStorage(
      "aj-to-wj-banner-shown"
    );
    if (!isCardShownToUser) {
      this.setShowCard(true);
    }
  }

  setShowCard = (value) => {
    this.setState({
      showCard: value
    });
  };

  render() {
    const { showCard } = this.state;
    return <>{showCard && <Card setShowCard={this.setShowCard}></Card>}</>;
  }
}

const styles = StyleSheet.create({
  wrapper: {
    position: "fixed",
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    backgroundColor: "#29203866"
  },
  cardContainer: {
    width: 328,
    borderRadius: 4,
    backgroundColor: "#fff",
    margin: "auto"
  },
  bottomSection: {
    paddingHorizontal: 30,
    paddingTop: 30,
    paddingBottom: 24,
    textAlign: "center"
  },
  secondaryInfo: {
    marginTop: 12,
    marginBottom: 32
  },
  button: {
    height: 48
  }
});

export default AjToWjTransitionCard;
