import { mLog } from "utils/logger";
import { Platform } from "react-native";

export const activateGoogleOptimize = (experimentId, template = null) => {
  let optimizeActivationAttempt = 0;
  let gaVariant = "";
  let promise = null;

  if (Platform.OS === "web") {
    promise = new Promise((resolve, reject) => {
      const intervalId = setInterval(() => {
        if (optimizeActivationAttempt < 5) {
          // So that unsupported browsers can see the page too
          optimizeActivationAttempt += 1;
          if (
            typeof window !== "undefined" &&
            window.google_optimize !== undefined
          ) {
            clearInterval(intervalId);
            gaVariant = window.google_optimize.get(experimentId);

            if (template) {
              gaVariant = template[gaVariant];
            }
            mLog(
              `=== GOOGLE OPTIMIZE -> EXP-ID: ${experimentId} VARIANT: ${gaVariant}`
            );
            resolve(gaVariant);
          }
        } else {
          clearInterval(intervalId);
          mLog("GOOGLE OPTIMIZE ISN'T SUPPORTED");
          resolve(gaVariant);
        }
      }, 100);
    });
  } else {
    promise = new Promise((resolve, reject) => {
      mLog("GOOGLE OPTIMIZE ISN'T SUPPORTED IN MOBILE");
      resolve(false);
    });
  }

  return promise;
};

export const getVariantWithTemplate = (experimentId, template = null) => {
  let gaVariant = "";
  if (Platform.OS === "web") {
    if (typeof window !== "undefined" && window.google_optimize !== undefined) {
      gaVariant = window.google_optimize.get(experimentId);
      if (template) {
        gaVariant = template[gaVariant];
      }
      mLog("GOOGLE OPTIMIZE VARIANT", gaVariant);
    } else {
      mLog("GOOGLE OPTIMIZE ISN'T SUPPORTED");
    }
  } else {
    mLog("GOOGLE OPTIMIZE ISN'T SUPPORTED IN MOBILE");
  }
  return gaVariant;
};
