
import React from "react";
import SText from "../SText";
import {
  disabledIconProps,
  disabledTextProps,
  iconProps,
  textProps
} from "./constants";

export const renderAffixIcon = (Icon, {
  type,
  disabled,
  props,
  disabledProps
}) => {
  let iconComponent = null;
  if (Icon) {
    iconComponent = (
      <Icon
        {...iconProps[type]}
        {...props}
        {...(disabled ? { ...disabledIconProps[type], ...disabledProps } : {})}
      />
    );
  }
  return iconComponent;
};

export const renderButtonText = (text, {
  type,
  disabled,
  props,
  disabledProps
}) => {
  let textComponent = null;
  if (text) {
    textComponent = (
      <SText
        {...textProps[type]}
        {...props}
        {...(disabled ? { ...disabledTextProps[type], ...disabledProps } : {})}
      >
        {text}
      </SText>
    );
  }
  return textComponent;
};