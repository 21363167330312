import Head from "next/head";
import { getDataFromTree } from "@apollo/client/react/ssr";
import { getToken as getTokenFromCookie, signOutUniversal, persistTokenUniversal } from "utils/authentication";
import redirect from "./redirect";
import initApollo from "./init-apollo";

/* eslint-disable */

export default App => {
  return class Apollo extends React.Component {
    static displayName = "withApollo(App)";
    static async getInitialProps(ctx) {
      const { Component, router } = ctx;
      const { req, res } = ctx.ctx;

      const apollo = initApollo(
        {},
        {
          getToken: () => getTokenFromCookie(req),
          persistToken: tokenObj => persistTokenUniversal(res)(tokenObj),
          signOut: () => signOutUniversal(res),
          redirectTo: target => redirect(res)(target)
        }
      );

      ctx.ctx.apolloClient = apollo; // make apolloClient available in getInitialProps

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx);
      }

      if (res && res.finished) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        return {};
      }

      // Run all GraphQL queries in the component tree
      // and extract the resulting data... to prevent loading for server side rendering
      if (!process.browser) {
        try {
          await getDataFromTree(<App {...appProps} Component={Component} router={router} apolloClient={apollo} />);
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // http://dev.apollodata.com/react/api-queries.html#graphql-query-data-error
          console.error("Error while running `getDataFromTree`", error);
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind();
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState
      };
    }

    constructor(props) {
      super(props);
      // `getDataFromTree` renders the component first, the client is passed off as a property.
      // After that rendering is done using Next's normal rendering pipeline
      this.apolloClient =
        props.apolloClient ||
        initApollo(props.apolloState, {
          getToken: () => getTokenFromCookie(undefined),
          persistToken: tokenObj => persistTokenUniversal(undefined)(tokenObj),
          signOut: () => signOutUniversal(undefined),
          redirectTo: target => redirect(undefined)(target)
        });
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />;
    }
  };
};
