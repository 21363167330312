/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, View } from "react-native";
import SClickableArea from "../SClickableArea";
import {
  buttonProps,
  buttonStyles,
  disabledButtonStyles,
} from "./constants";
import {
  renderAffixIcon,
  renderButtonText
} from "./utils";

// Note: For displaying custom component inside button pass that component as children
// e.g. <SButton ...><CustomComponent /></SButton>
const SButton = (props) => {
  const {
    type,
    text,
    disabled,
    prefix,
    prefixIcon,
    suffix,
    suffixIcon,
    children,
    textProps,
    disabledTextProps,
    prefixIconProps,
    disabledPrefixIconProps,
    suffixIconProps,
    disabledSuffixIconProps,
    containerStyle,
    disabledContainerStyle,
    wrapperStyle,
    ...restProps
  } = props;

  const { containerStyle: textContainerStyle, ...restTextProps } = textProps;

  const styles = createStyles(props);

  return (
    <SClickableArea
      disabled={disabled}
      containerStyle={[
        styles.container,
        containerStyle,
        disabled ? [styles.disabledContainer, disabledContainerStyle] : undefined
      ]}
      {...buttonProps[type]}
      {...restProps}
    >
      <View style={[styles.wrapper, wrapperStyle]}>
        {prefix}
        {renderAffixIcon(prefixIcon, {
          type,
          disabled,
          props: prefixIconProps,
          disabledProps: disabledPrefixIconProps
        })}
        {children || (
          <>
            {renderButtonText(text, {
              type,
              disabled,
              props: {
                containerStyle: [styles.text, textContainerStyle],
                ...restTextProps
              },
              disabledProps: disabledTextProps
            })}
          </>
        )}
        {renderAffixIcon(suffixIcon, {
          type,
          disabled,
          props: suffixIconProps,
          disabledProps: disabledSuffixIconProps
        })}
        {suffix}
      </View>
    </SClickableArea>
  );
};

const createStyles = ({
  type,
  prefix,
  prefixIcon,
  suffix,
  suffixIcon
}) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      ...buttonStyles[type]
    },
    wrapper: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    },
    disabledContainer: {
      ...disabledButtonStyles[type]
    },
    text: {
      paddingLeft: prefix || prefixIcon ? 8 : 0,
      paddingRight: suffix || suffixIcon ? 8 : 0
    }
  });

SButton.defaultProps = {
  type: "primary",
  text: "",
  textProps: {},
  containerStyle: {},
  onClick: () =>{},
  suffixIcon: null
}

SButton.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "tertiary", "danger", "link", "text", "chip", "dotted"]),
  text: PropTypes.string,
  textProps: PropTypes.object, // Same as SText props
  containerStyle: PropTypes.object,
  onClick: PropTypes.func,
  suffixIcon: PropTypes.any
};

export default SButton;
