import Colors from '../../config/colors';

// Default SText props based on type of button
export const textProps = {
  primary: {
    weight: '500',
    color: 'white',
  },
  secondary: {
    weight: '500',
    color: Colors.Purple[1],
  },
  tertiary: {
    weight: '500',
    color: Colors.Purple[1],
  },
  danger: {
    weight: '500',
    color: Colors.Red[4],
  },
  link: {
    weight: '500',
    color: Colors.Purple[1],
  },
  text: {
    color: Colors.BlueGrey[12],
  },
  chip: {
    color: Colors.BlueGrey[12],
  },
  dotted: {
    color: Colors.Purple[1],
  },
};

// Default Icon props based on type of button
export const iconProps = {
  primary: {
    fill: 'white',
  },
  secondary: {
    fill: Colors.BlueGrey[12],
  },
  tertiary: {
    fill: Colors.Purple[1],
  },
  danger: {
    fill: Colors.Red[4],
  },
  link: {
    fill: Colors.Orange[5]
  },
  text: {
    fill: Colors.BlueGrey[12],
  },
  chip: {
    fill: Colors.BlueGrey[7],
  },
  dotted: {
    fill: Colors.Purple[1],
  },
};

// Default button styles according to type of button
export const buttonStyles = {
  primary: {
    height: 40,
    backgroundColor: Colors.Purple[1],
    paddingHorizontal: 16,
    borderRadius: 8
  },
  secondary: {
    height: 40,
    paddingHorizontal: 16,
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: Colors.BlueGrey[2],
    backgroundColor: 'white',
  },
  tertiary: {
    height: 40,
    paddingHorizontal: 16,
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.Purple[1],
    backgroundColor: 'white',
  },
  danger: {
    height: 40,
    paddingHorizontal: 16,
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: Colors.BlueGrey[2],
    backgroundColor: 'white',
  },
  link: {
    backgroundColor: 'transparent',
    borderRadius: 2,
  },
  text: {
    backgroundColor: 'transparent',
    borderRadius: 2,
  },
  chip: {
    height: 32,
    paddingHorizontal: 16,
    borderRadius: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.BlueGrey[2],
    backgroundColor: 'white',
  },
  dotted: {
    height: 40,
    paddingHorizontal: 16,
    borderRadius: 2,
    borderWidth: 2,
    borderStyle: 'dotted',
    borderColor: Colors.Purple[1],
    backgroundColor: 'white',
  },
};

// Disabled button styles according to type of button
export const disabledButtonStyles = {
  primary: {
    backgroundColor: "#b4b7bd"
  },
  secondary: {
    opacity: 0.48,
  },
  tertiary: {
    opacity: 0.48,
  },
  danger: {
    opacity: 0.48,
  },
  link: {
    opacity: 0.48,
  },
  text: {
    opacity: 0.48,
  },
  chip: {
    opacity: 0.48,
  },
  dotted: {
    opacity: 0.48,
  },
};

// Default SClickableArea props according to type of button
export const buttonProps = {
  primary: {
    touchableType: 'opacity',
    activeOpacity: 0.6,
    underlayColor: Colors.Grey[1],
  },
  secondary: {
    touchableType: 'highlight',
    activeOpacity: 0.6,
    underlayColor: Colors.Grey[1],
  },
  tertiary: {
    touchableType: 'highlight',
    activeOpacity: 0.6,
    underlayColor: Colors.Grey[1],
  },
  danger: {
    touchableType: 'highlight',
    activeOpacity: 0.6,
    underlayColor: Colors.Grey[1],
  },
  link: {
    touchableType: 'opacity',
    activeOpacity: 0.6,
    underlayColor: Colors.Orange[5]
  },
  text: {
    touchableType: 'opacity',
    activeOpacity: 0.6,
    underlayColor: Colors.Grey[1],
  },
  chip: {
    touchableType: 'highlight',
    activeOpacity: 0.6,
    underlayColor: Colors.Grey[1],
  },
  dotted: {
    touchableType: 'highlight',
    activeOpacity: 0.6,
    underlayColor: Colors.Grey[1],
  },
};

// Disabled SText props according to type of button
export const disabledTextProps = {
  primary: {
    color: 'white',
  },
};

// Disabled Icon props according to type of button
export const disabledIconProps = {
  primary: {
    fill: Colors.Grey[5],
  },
};
