import gql from "graphql-tag";

export const ADD_ENDPOINT = gql`
  mutation addBrowserFCMToken($payload: BrowserDetails!) {
    addBrowserFCMToken(payload: $payload)
  }
`;

export const SOFT_DELETE_NON_FCM_ENDPOINT = gql`
 mutation SoftDeleteBrowserEndpoint($payload: UpdateBrowserDetails!) {
  SoftDeleteBrowserEndpoint(payload: $payload)
  }
`;

export const UPDATE_ANDROID_PUSH_TOKEN = gql`
mutation updateAndroidPushToken($refresh_token:String,$token:String!){
  push_token_mutations{
    update(refresh_token:$refresh_token, token:$token)
  }
}
`;