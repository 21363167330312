import { Router } from "../routes";

const redirect = (res, target) => (statusCode) => {
  if (!process.browser) {
    // server
    // 303: "See other"
    res.writeHead(statusCode, {
      Location: target,
      "Cache-Control": "private, no-cache, no-store, must-revalidate" // prevent cloudflare from caching this response
    });
    res.end();
  } else {
    Router.pushRoute(target); // make sure a history entry is created to enable back nav
  }
};

const redirectTo = (res) => (target) => {
  redirect(res, target)(303);
};

export const hardRedirectTo = (res) => (target) => {
  if (!process.browser) {
    redirectTo(res)(target);
  } else {
    window.location = target;
  }
};

export const redirect301 = (res) => (target) => {
  redirect(res, target)(301);
};

export default redirectTo;
