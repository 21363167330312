import React, { Component } from "react";

import { graphQLQueryHelper } from "utils/graphQL";
import gql from "graphql-tag";
import { mLog } from "utils/logger";
import config from "config";

const USER_QUERY = gql`
  query {
    me {
      id
      first_name
      last_name
      mobile
      email
      email_verified
      mobile_verified
      stakeholder {
        ... on Candidate {
          id
          created
          functional_areas {
            name
          }
          address {
            place {
              short_formatted_address
            }
          }
        }
      }
    }
  }
`;

class Wootric extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
    const { authToken, client } = this.props;
    if (!authToken) {
      return;
    }
    const { data: userData, error } = await graphQLQueryHelper(client)({
      query: USER_QUERY
    });
    if (error) {
      mLog("Error on getting user data", error);
    }
    const data = userData || {};
    const { me } = data;

    const getUserField = (field) => {
      return me && me[field];
    };

    const getStakeholderField = (field) => {
      const stakeholder = getUserField("stakeholder");
      return stakeholder && stakeholder[field];
    };

    const getUserAddress = () => {
      const address = getStakeholderField("address");
      return address && address.place && address.place.short_formatted_address;
    };

    const setupScript = document.createElement("script");
    setupScript.async = true;

    // wootric_survey_immediately = true;
    // wootric_no_surveyed_cookie = true;
    const NA = "NA";
    setupScript.innerHTML = `
       window.wootricSettings = {
          email: '${getUserField("email") || NA}',
          external_id: '${getUserField("id") || NA}',
          created_at: ${this.getTime(
            getStakeholderField("created")
          )} || '9876543210',
          account_token: '${config.WOOTRIC_ACCOUNT_TOKEN}',
          modal_footprint: "compact",
          properties: {
            Platform: "Mobile Web",
            Email: '${getUserField("email") || NA}',
            Mobile: '${getUserField("mobile") || NA}',
            CandidateId: '${getStakeholderField("id") || NA}',
            IsLoggedIn: ${!!authToken},
            JobRole: '${
              this.getFunctionalArea(getStakeholderField("functional_areas")) ||
              NA
            }',
            Geography: '${getUserAddress() || NA}'
          }
      };
    `;

    document.body.appendChild(setupScript);

    // Beacon
    const beacon = document.createElement("script");
    beacon.async = true;

    beacon.src = "https://disutgh7q0ncc.cloudfront.net/beacon.js";
    // beacon.src = "https://cdn.wootric.com/wootric-sdk.js";
    beacon.onload = function () {
      if (window.wootric) {
        window.wootric("run");
      }
    };
    document.body.appendChild(beacon);
  };

  getTime = (date) => {
    if (date) {
      const d = new Date(date);
      return d.getTime() / 1000;
    }
    return false;
  };

  getFunctionalArea = (fa) => {
    if (fa && fa.length > 0) {
      return fa.reduce((falist, fa1, index) => {
        const { name } = fa1;
        if (index === 0) {
          return name;
        }
        return falist + ", " + name;
      }, "");
    }
    return null;
  };

  render() {
    const { authToken } = this.props;

    if (authToken) {
      return <div />;
    }
    return null;
  }
}

export default Wootric;
