import { Observable } from "@apollo/client";
import { Dimensions } from "react-native";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { mLog, mWarn } from "utils/logger";
import config from "config";
import { navigate } from "../routing/NavRouter";
import Platform from "utils/platform";

function refreshTokenSchema(variables) {
  return {
    operationName: "RefreshOauthToken",
    query: `mutation RefreshOauthToken($refresh_token: String!) 
    {
      refreshOauthToken(refresh_token: $refresh_token)
      {
        token
        expires
        refresh_token
      }
    }`,
    variables
  };
}

const DesktopWidth =
  Platform.OS === "web" && Dimensions.get("window").width > 800;

function refreshTokenRequest(tokenObj, persistToken) {
  if (tokenObj.hasOwnProperty("refresh_token")) {
    return fetch(config.API_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(
        refreshTokenSchema({ refresh_token: tokenObj.refresh_token })
      ),
      headers: {
        "Content-Type": "application/json",
        "X-AJClient":
          Platform.OS === "web" && DesktopWidth
            ? "DesktopWeb.Candidate"
            : Platform.OS === "web" && !DesktopWidth
            ? "MobileWeb.Candidate"
            : "MobileApp.Candidate",
        "X-AJ-PLATFORM": 0
      }
    })
      .then((response) => {
        if (response.status !== 200) throw new Error("Non-200 response");
        return response.json();
      })
      .then((json) => {
        const { data } = json;
        mLog("got new token data", data);

        if (data && data.refreshOauthToken && data.refreshOauthToken.token) {
          return persistToken(data.refreshOauthToken);
        } else {
          throw new Error("Invalid data");
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  } else {
    return Promise.reject(
      new Error("refresh_token param missing in token object")
    );
  }
}

const isTokenInvalidOrExpired = (networkError, graphQLErrors) => {
  if (
    networkError &&
    networkError.statusCode === 400 &&
    graphQLErrors &&
    graphQLErrors[0] &&
    graphQLErrors[0].extensions &&
    (graphQLErrors[0].extensions.code === "TOKEN_EXPIRED" ||
      graphQLErrors[0].extensions.code === "UNAUTHENTICATED")
  ) {
    return true;
  }

  return false;
};

export const getErrorLink = ({ getToken, persistToken, signOut, redirectTo }) =>
  onError(({ networkError, graphQLErrors, operation, forward }) => {
    if (graphQLErrors) mWarn("graphQLErrors", graphQLErrors);
    if (networkError) mWarn("networkError", networkError);

    if (isTokenInvalidOrExpired(networkError, graphQLErrors)) {
      // we got an unauthorized error code for the query we tried. Let's regenerate the token and try again
      return new Observable((observer) => {
        if (Platform.OS === "web") {
          const tokenObj = getToken();
          refreshTokenRequestFun(
            tokenObj,
            persistToken,
            signOut,
            redirectTo,
            observer,
            operation,
            forward
          );
        } else {
          getToken()
            .then((tokenObj) => {
              refreshTokenRequestFun(
                tokenObj,
                persistToken,
                signOut,
                redirectTo,
                observer,
                operation,
                forward
              );
            })
            .catch((error) => {});
        }
      });
    }
  });

const refreshTokenRequestFun = (
  tokenObj,
  persistToken,
  signOut,
  redirectTo,
  observer,
  operation,
  forward
) => {
  refreshTokenRequest(tokenObj, persistToken)
    .then(() => {
      const subscriber = {
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer)
      };

      // Retry last failed request
      forward(operation).subscribe(subscriber);
    })
    .catch((error) => {
      mLog("got an error while refreshing", error);
      signOut();

      if (Platform.OS === "web") {
        redirectTo("/candidate/register/"); // support SSR
      } else {
        navigate("loginRegistration", {}, "replace", "publicStack");
      }

      observer.error(error);
    });
};

export const getAuthLink = (getToken) =>
  setContext(async (_, { headers }) => {
    let bearerToken = "";

    if (getToken) {
      const tokenObj = await getToken();
      //mLog("tokenObj=======================", tokenObj);

      if (tokenObj) {
        bearerToken = tokenObj.token;
      }
    }

    return {
      headers: {
        ...headers,
        authorization: bearerToken ? `Bearer ${bearerToken}` : "",
        "X-AJClient":
          Platform.OS === "web" && DesktopWidth
            ? "DesktopWeb.Candidate"
            : Platform.OS === "web"
            ? "MobileWeb.Candidate"
            : "MobileApp.Candidate",
        "X-AJ-PLATFORM": 0
      }
    };
  });
